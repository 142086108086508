import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { COST_OPTIONS } from 'mgr/actualslideout/actions/BookActions'
import BookStepComponent from 'mgr/actualslideout/components/BookStepComponent'
import { AddButton, CloseButton } from 'mgr/actualslideout/components/view/ViewCommon'
import { FlexColumnContainer, FlexRowContainer } from 'mgr/actualslideout/components/view/ViewLayout'
import { selectBookModeTimeSlotAvailableTableOptions } from 'mgr/actualslideout/selectors/BookSelectors'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import GenericTagsDropDown from 'mgr/lib/components/GenericTagsDropDown'
import TextInput, { InputRestrictions, ValidatorTypes } from 'mgr/lib/forms/TextInput'
import DropdownMenu from 'svr/component-lib/Manager/Menus/DropdownMenu'
import { Col, ContainerFluid, Row } from 'svr/lib/styled-bootstrap-grid'
import RadioGroupStrip from '../../../../../component-lib/Manager/RadioGroup/RadioGroupStrip'
import { LoyaltyPerks } from '../../components/view/DetailCard'
import { ExcludeFromShiftPacingField } from 'mgr/actualslideout/components/view/ExcludeFromShiftPacing'
import * as BookAvailabilityActions from 'mgr/actualslideout/actions/BookAvailabilityActions'
import { ClientCheckinDisplay } from 'mgr/actualslideout/components/client/ClientHotelLookupResult'
import { isPastTime } from 'svr/common/TimeUtil'
import moment from 'moment-timezone'

const SectionDivider = styled.hr`
  border: 1px dashed ${props => props.theme.color.greyMuted};
  margin: 15px 12px;
`
const FieldGroup = styled.div`
  margin: 15px 12px;
  ${props => props.theme.clearFix};
`

const FormContainer = styled(ContainerFluid).attrs({ sidePadding: '10px' })``

const FieldCol = styled(Col).attrs({ sidePadding: '5px' })`
  padding-top: 5px;
  padding-bottom: 5px;
`

const NEW_NAME_ID = '--new--'
const NEW_NAME_FAKE_USER = {
  id: NEW_NAME_ID,
  name: '+ add new name',
  is_user: false,
}
const DetailsStep = ({
  actions,
  isCollapsed,
  scrollIntoView,
  reservationNotes,
  venue,
  venueResTagGroups,
  reservationTags,
  availableTableOptions,
  isAutoAssign,
  isCustomAssign,
  selectedHotelClient,
  selectedTableIds,
  canSelectBookedBy,
  venueBookedByNames,
  additionalVenueBookedByNames,
  currentUserId,
  selectedBookedBy,
  validateFieldMaps,
  additionalBookedBy,
  customBookedByEnabled,
  newPromoterName,
  numberOfAdditionalBookedBySlots,
  perks,
  visibleCostOptions,
  selectedCostOption,
  costOptionAmount,
  customFields,
  perksList,
  clientAutoTags,
  excludeFromShiftPacing,
  setExcludeFromShiftPacing,
  canOverbook,
}) => {
  const hasSelection = false
  const isCollapsible = false
  const onToggle = () => {
    /* actions.toggleStep('details') */
  }
  const onCompleteScrollIntoView = () => {
    actions.clearScrollIntoView()
  }
  const [title, subTitle] = ['Additional Details', null]
  let allowedBookedByNames
  let additionalAllowedBookedByNames
  const hasBookedBy = !_.isEmpty(selectedBookedBy)
  const hasAdditionalBookedBy = !_.isEmpty(additionalBookedBy)
  const memberChatIdx = additionalBookedBy.findIndex(bb => bb.name === 'Member Chat')

  const showExcludedFromShiftPacing = window.globalInit.venueSettings.exclude_from_shift_pacing_enabled

  if (canSelectBookedBy) {
    if (hasBookedBy && !_.find(venueBookedByNames, { id: selectedBookedBy.id })) {
      allowedBookedByNames = [...venueBookedByNames, selectedBookedBy]
    } else {
      allowedBookedByNames = venueBookedByNames
    }

    if (hasAdditionalBookedBy) {
      const extraBookedByNames = _.differenceBy(additionalBookedBy, additionalVenueBookedByNames, 'id')
      additionalAllowedBookedByNames = [...additionalVenueBookedByNames, ...extraBookedByNames]
    } else {
      additionalAllowedBookedByNames = additionalVenueBookedByNames
    }
  } else {
    if (hasBookedBy) {
      allowedBookedByNames = [selectedBookedBy]
    } else {
      const currentUserBookedBy = _.find(venueBookedByNames, bb => bb.id === currentUserId)
      allowedBookedByNames = currentUserBookedBy !== undefined ? [currentUserBookedBy] : []
    }

    if (hasAdditionalBookedBy) {
      additionalAllowedBookedByNames = _.concat(additionalBookedBy)
    } else {
      additionalAllowedBookedByNames = []
    }
  }

  const { showPerksField } = venue
  const makeBookedBy = bookedBy => ({
    name: bookedBy.name,
    value: bookedBy.id,
    selectValue: bookedBy,
  })
  const bookedByOptions = allowedBookedByNames.map(makeBookedBy)
  const additionalBookedByOptions = additionalAllowedBookedByNames.map(makeBookedBy)

  const tableOpenPath = isCustomAssign ? ['init', 'custom'] : ['init']
  const tableDropdownValue = isAutoAssign ? [{ value: 'auto' }] : selectedTableIds

  const validateFieldMap = validateFieldMaps.availability
  validateFieldMap.selected_booked_by = {
    isValid: () => (_.isNil(selectedBookedBy) ? 'Please select a booked by name' : true),
  }

  const handleFirstBbChange = val => {
    actions.changeSelectedBookedBy(val)
    if (val === NEW_NAME_FAKE_USER) {
      actions.enableCustomBookedBy()
    }
  }

  const customFieldInputs = venue.customFieldsConfig.actual.reduce((result, customField) => {
    if (!customField.enabled) {
      return result
    }
    const isIndexedField = Boolean(customField.db_name)
    const value = (isIndexedField ? customFields[customField.db_name] : customFields.custom_unindexed[customField.system_name]) || ''
    if (value === '' && !customField.editable) {
      return result
    }
    const onChangeCustomField = isIndexedField
      ? v => actions.changeCustomIndexedField(customField.db_name, v)
      : v => actions.changeCustomUnindexedField(customField.system_name, v)
    result.push(
      <TextInput
        label={customField.name.toUpperCase()}
        charLimit={1000}
        value={value}
        onChange={onChangeCustomField}
        disabled={!customField.editable}
      />
    )
    return result
  }, [])

  const isCheckOutDatePast = selectedHotelClient?.check_out_date_display
    ? isPastTime(
        0,
        venue.timezone,
        venue.startOfDayHour,
        moment(selectedHotelClient.check_out_date_display, venue.locale === 'en_US' ? 'MM/DD/YYYY' : 'DD/MM/YYYY')
      )
    : undefined

  return (
    <BookStepComponent
      {...{
        title,
        subTitle,
        onToggle,
        onCompleteScrollIntoView,
        isCollapsed,
        isCollapsible,
        scrollIntoView,
      }}
      testId="sr-section-additional_details"
      isEmpty={!hasSelection}
    >
      <div>
        <FieldGroup key="FieldGroup_ReservationTags">
          <GenericTagsDropDown
            name="RESERVATION TAGS"
            tagGroups={venueResTagGroups}
            venueId={venue.id}
            selectedTags={reservationTags}
            onChangeHandler={actions.changeReservationTags}
            height="44px"
            width="100%"
          />
        </FieldGroup>
        <FieldGroup key="FieldGroup_ReservationNotes">
          <TextInput
            label="RESERVATION NOTES"
            charLimit={5000}
            isMultiLine
            forceIndent
            minRows={1}
            maxRows={3}
            value={reservationNotes}
            onChange={actions.changeReservationNotes}
          />
        </FieldGroup>
        {selectedHotelClient?.client_requests && (
          <FieldGroup key="FieldGroup_ExternalReservationNotes">
            <TextInput
              label="External Reservation Notes"
              charLimit={5000}
              isMultiLine
              forceIndent
              minRows={1}
              maxRows={3}
              value={selectedHotelClient?.client_requests}
              disabled
            />
          </FieldGroup>
        )}
        {(selectedHotelClient?.confirmation_num || selectedHotelClient?.room_num) && (
          <FieldGroup key="FieldGroup_HotelInfo">
            <FlexRowContainer style={{ alignItems: 'flex-start' }}>
              <FlexRowContainer key="FlexRowContainer__HotelInfo" style={{ flexWrap: 'wrap', width: 'auto' }}>
                {selectedHotelClient.selectedHotelId && (
                  <FlexColumnContainer style={{ width: '180px', marginBottom: '10px', marginRight: '9px' }} key="HotelInfo__HotelId">
                    <TextInput testId="sr-hotel-id" disabled label="HOTEL ID" value={selectedHotelClient.selectedHotelId} />
                  </FlexColumnContainer>
                )}
                {selectedHotelClient.confirmation_num && !isCheckOutDatePast && (
                  <FlexColumnContainer style={{ width: '180px', marginBottom: '10px', marginRight: '9px' }} key="HotelInfo__Confirmation">
                    <TextInput
                      testId="sr-hotel-confirmation"
                      disabled
                      label="HOTEL CONFIRMATION NUMBER"
                      value={selectedHotelClient.confirmation_num}
                    />
                  </FlexColumnContainer>
                )}
                {selectedHotelClient.room_num && !isCheckOutDatePast && (
                  <FlexColumnContainer style={{ width: '180px', marginBottom: '10px', marginRight: '9px' }} key="HotelInfo__Room">
                    <TextInput testId="sr-hotel-room" disabled label="HOTEL ROOM NUMBER" value={selectedHotelClient.room_num} />
                  </FlexColumnContainer>
                )}
                {selectedHotelClient.rate_code && (
                  <FlexColumnContainer style={{ width: '180px', marginBottom: '10px', marginRight: '9px' }} key="HotelInfo__RateCode">
                    <TextInput testId="sr-hotel-rate-code" disabled label="HOTEL RATE CODE" value={selectedHotelClient.rate_code} />
                  </FlexColumnContainer>
                )}
              </FlexRowContainer>
              <FlexRowContainer key="FlexRowContainer__HotelInfo" style={{ flexWrap: 'wrap', width: 'auto' }}>
                {selectedHotelClient.check_in_date_display && selectedHotelClient.check_out_date_display && !isCheckOutDatePast && (
                  <FlexColumnContainer style={{ width: '180px', marginBottom: '10px', marginRight: '9px' }} key="HotelInfo__dates">
                    <ClientCheckinDisplay client={selectedHotelClient} />
                  </FlexColumnContainer>
                )}
              </FlexRowContainer>
            </FlexRowContainer>
          </FieldGroup>
        )}
        <FieldGroup key="FieldGroup_TableBookedBy">
          <FlexRowContainer key="FlexRowContainer_TableBookedBy'">
            <DropdownMenu
              testId="sr-picker-table_number"
              key="table_selector"
              name="TABLE #"
              choices={availableTableOptions}
              selectedValues={tableDropdownValue}
              optionsContainerStyle={{
                maxWidth: 400,
              }}
              isLightTheme
              openPath={tableOpenPath}
              onChangeHandler={actions.changeSelectedTables}
              onNestedChangeHandler={updatedPath => actions.changeIsCustomAssign(updatedPath.indexOf('custom') > -1)}
              style={{
                width: 180,
              }}
            />

            <DropdownArrowsPicker
              key={`${selectedBookedBy.id}_default_selected`}
              name="BOOKED BY"
              choices={bookedByOptions}
              value={(selectedBookedBy ?? {}).id}
              isLightTheme
              useOutsideLabel
              onChangeHandler={handleFirstBbChange}
              disabled={!canSelectBookedBy}
              style={{
                width: 180,
              }}
            />
            {memberChatIdx !== -1 && (
              <DropdownArrowsPicker
                name="BOOKED BY"
                choices={additionalBookedByOptions}
                value={(additionalBookedBy[memberChatIdx] ?? {}).id}
                isLightTheme
                useOutsideLabel
                disabled
                style={{
                  width: 180,
                }}
              />
            )}
            {canSelectBookedBy && customBookedByEnabled ? (
              <TextInput
                label="ADDITIONAL BOOKED BY NAME"
                charLimit={64}
                isMultiLine={false}
                minRows={1}
                maxRows={1}
                value={newPromoterName}
                // eslint-disable-next-line no-param-reassign
                ref={e => (validateFieldMaps.details.additional_booked_by_name = e)}
                validator={ValidatorTypes.notEmpty}
                onChange={actions.changeCustomBookedBy}
              />
            ) : null}

            {canSelectBookedBy ? <AddButton style={{ marginTop: '12px' }} onClick={actions.addAdditionalBookedBySlot} /> : null}
          </FlexRowContainer>
        </FieldGroup>
        {(hasAdditionalBookedBy || numberOfAdditionalBookedBySlots > 0) && (
          <FieldGroup key="FieldGroup_AdditionalBookedBy" style={{ marginTop: '5px' }}>
            <FlexRowContainer key="FlexRowContainer_AdditionalBookedBy" flexWrap="wrap">
              {_.map(
                additionalBookedBy,
                (singleBookedBy, idx) =>
                  idx !== memberChatIdx && (
                    <FlexRowContainer key={`${singleBookedBy.id}_addtl_booked_by_wrapper`} style={{ width: '220px', marginTop: '10px' }}>
                      <DropdownArrowsPicker
                        key={`${singleBookedBy.id}_addtl_booked_by_picker`}
                        name="BOOKED BY"
                        choices={additionalBookedByOptions}
                        value={(singleBookedBy || {}).id}
                        isLightTheme
                        onChangeHandler={val => actions.changeAdditionalBookedBySlot(val, idx)}
                        disabled={!canSelectBookedBy}
                        style={{
                          width: 180,
                        }}
                      />

                      {canSelectBookedBy ? (
                        <CloseButton
                          key={`${singleBookedBy.id}_remove`}
                          onClick={() => actions.removeAdditionalBookedBy(singleBookedBy.id)}
                        />
                      ) : null}
                    </FlexRowContainer>
                  )
              )}

              {_.times(numberOfAdditionalBookedBySlots, idx => (
                <FlexRowContainer key={`${idx}_slot_wrapper`} style={{ width: '220px', marginTop: '10px' }}>
                  <DropdownArrowsPicker
                    key={idx}
                    name="BOOKED BY"
                    choices={additionalBookedByOptions}
                    isLightTheme
                    onChangeHandler={actions.setBookedByFirstTime}
                    disabled={!canSelectBookedBy}
                    style={{
                      width: 180,
                    }}
                  />

                  {canSelectBookedBy ? <CloseButton key={`${idx}_slot_remove`} onClick={actions.removeAdditionalBookedBySlot} /> : null}
                </FlexRowContainer>
              ))}
            </FlexRowContainer>
          </FieldGroup>
        )}
        {showExcludedFromShiftPacing && (
          <FieldGroup key="FieldGroup_ExcludeFromShiftPacing" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
            <FlexRowContainer key="FlexRowContainer_ExcludeFromShiftPacing" flexWrap="wrap">
              <ExcludeFromShiftPacingField
                excludeFromShiftPacing={excludeFromShiftPacing}
                onChange={setExcludeFromShiftPacing}
                disabled={!canOverbook}
              />
            </FlexRowContainer>
          </FieldGroup>
        )}
      </div>
      {showPerksField ? (
        <div>
          <SectionDivider />
          <FieldGroup key="FieldGroup_Perks">
            <TextInput
              label="PERKS"
              charLimit={1000}
              isMultiLine
              forceIndent
              minRows={1}
              maxRows={3}
              style={{ width: '80%' }}
              value={perks}
              onChange={actions.changePerksField}
            />
          </FieldGroup>
          <LoyaltyPerks autoTags={clientAutoTags} perksList={perksList} reservationTags={reservationTags} />
        </div>
      ) : null}

      {!_.isEmpty(visibleCostOptions) ? (
        <FieldGroup key="FieldGroup_CostOptions">
          <FlexRowContainer>
            <FlexColumnContainer style={{ maxWidth: '25%' }}>
              <TextInput
                disabled={selectedCostOption === COST_OPTIONS.NO_MINIMUM || selectedCostOption === COST_OPTIONS.COMPED}
                label="PRICE"
                inputRestriction={InputRestrictions.number}
                value={`${costOptionAmount}`}
                onChange={actions.changeCostOptionAmount}
              />
            </FlexColumnContainer>

            <FlexColumnContainer style={{ marginTop: '16px', maxWidth: '50%', marginLeft: '10px' }}>
              <RadioGroupStrip
                options={visibleCostOptions}
                selected={selectedCostOption}
                groupName="costOptions"
                onChange={actions.changeSelectedCostOption}
              />
            </FlexColumnContainer>
          </FlexRowContainer>
        </FieldGroup>
      ) : null}
      {!_.isEmpty(customFieldInputs) ? (
        <div>
          <SectionDivider />
          <FormContainer>
            <FieldGroup key="FieldGroup_CustomFields">
              {customFieldInputs
                .reduce((rowResults, customField, index) => {
                  const rowIndex = Math.floor(index / 3)
                  if (!rowResults[rowIndex]) {
                    // eslint-disable-next-line no-param-reassign
                    rowResults[rowIndex] = []
                  }
                  rowResults[rowIndex].push(customField)
                  return rowResults
                }, [])
                .map((rowItems, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Row key={idx}>
                    {rowItems.map((customFieldInput, idx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <FieldCol xs={12} md={4} key={idx}>
                        {customFieldInput}
                      </FieldCol>
                    ))}
                  </Row>
                ))}
            </FieldGroup>
          </FormContainer>
        </div>
      ) : null}
    </BookStepComponent>
  )
}

DetailsStep.propTypes = {
  actions: React.PropTypes.object,
  isCollapsed: React.PropTypes.bool,
  scrollIntoView: React.PropTypes.bool,
  reservationNotes: React.PropTypes.string.isRequired,
  venueResTagGroups: React.PropTypes.array.isRequired,
  reservationTags: React.PropTypes.array.isRequired,
  availableTableOptions: React.PropTypes.array.isRequired,
  isAutoAssign: React.PropTypes.bool.isRequired,
  isCustomAssign: React.PropTypes.bool.isRequired,
  selectedTableIds: React.PropTypes.array,
  canSelectBookedBy: React.PropTypes.bool.isRequired,
  venueBookedByNames: React.PropTypes.array.isRequired,
  additionalVenueBookedByNames: React.PropTypes.array.isRequired,
  currentUserId: React.PropTypes.string.isRequired,
  selectedBookedBy: React.PropTypes.object,
  validateFieldMaps: React.PropTypes.object.isRequired,
  additionalBookedBy: React.PropTypes.array,
  customBookedByEnabled: React.PropTypes.bool,
  newPromoterName: React.PropTypes.string,
  perks: React.PropTypes.string,
  perksList: React.PropTypes.array,
  clientAutoTags: React.PropTypes.array,
}

DetailsStep.defaultProps = {
  actions: {},
}

const mapStateToProps = state => {
  const venue = state.bookState.selectedVenue
  const COST_OPTION_LABELS = {
    COMPED: 'COMP',
    MINIMUM_DOLLARS: `MIN ${venue.currencySymbol}`,
    MINIMUM_BOTTLES: 'MIN BTLS',
    NO_MINIMUM: 'NO MIN',
    TOTAL_DOLLARS: `TOTAL ${venue.currencySymbol}`,
  }
  const { availableTableOptions, isCustomAssign } = selectBookModeTimeSlotAvailableTableOptions(state)
  return {
    venue,
    isCollapsed: state.bookState.isStepCollapsed.details,
    stepHeightChangeTimestamp: state.bookState.stepHeightChangeTimestamps.details,
    scrollIntoView: state.bookState.scrollIntoViewStep === 'details',
    reservationNotes: state.bookDetailsState.reservationNotes,
    venueResTagGroups: state.bookDetailsState.reservationTagGroupsByVenue[venue.id] ?? [],
    reservationTags: state.bookDetailsState.reservationTags,
    availableTableOptions,
    isAutoAssign: state.bookDetailsState.isAutoAssign,
    isCustomAssign,
    selectedHotelClient: state.bookClientState.selectedClient?.hotel_client,
    selectedTableIds: state.bookDetailsState.selectedTableIds,
    canSelectBookedBy: venue.permissions.canSelectBookedBy && state.bookDetailsState.canEditBookedBy,
    venueBookedByNames: _.concat([NEW_NAME_FAKE_USER], state.bookDetailsState.bookedByNamesByVenue[venue.id] ?? []),
    currentUserId: state.appState.user.id,
    selectedBookedBy: state.bookDetailsState.selectedBookedBy,
    additionalBookedBy: state.bookDetailsState.additionalBookedBy,
    numberOfAdditionalBookedBySlots: state.bookDetailsState.numberOfAdditionalBookedBySlots,
    customBookedByEnabled: state.bookDetailsState.customBookedByEnabled,
    newPromoterName: state.bookDetailsState.newPromoterName,
    visibleCostOptions: venue.costOptions.map(option => ({
      label: COST_OPTION_LABELS[option],
      value: option,
    })),
    selectedCostOption: state.bookDetailsState.selectedCostOption,
    costOptionAmount: state.bookDetailsState.costOptionAmount,
    customFields: state.bookDetailsState.customFields,
    additionalVenueBookedByNames: state.bookDetailsState.bookedByNamesByVenue[venue.id] ?? [],
    perks: state.bookDetailsState.perks,
    perksList: state.perksList.perks,
    clientAutoTags: state.bookClientState.selectedClient?.client_tags,
    excludeFromShiftPacing: state.bookAvailabilityState.excludeFromShiftPacing,
    canOverbook: state.appState.userDomain.can_overbook,
  }
}

const mapDispatchToProps = {
  setExcludeFromShiftPacing: BookAvailabilityActions.setExcludeFromShiftPacing,
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsStep)
